'use client'

import { Fragment, HTMLAttributes, useEffect, useState } from 'react'

import classNames from 'classnames'
import { isEmpty } from 'lodash'
import { Button, Loader, EmptyState, Container, Text, Spacer, Animation } from '@vinted/web-ui'

import useAsset from 'hooks/useAsset'
import useTracking from 'hooks/useTracking'
import useBreakpoint from 'hooks/useBreakpoint'
import { logMessage } from 'libs/utils/window'
import { clickEvent, clickFilterEvent, selectCatalogEvent } from 'libs/common/event-tracker/events'

import { FormattedMessage } from 'components/@support'
import InfiniteScroll from 'components/InfiniteScroll'
import SortFilter from 'components/SortFilter'
import CatalogFilter from 'components/CatalogFilter'

import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { ITEM_UPLOAD_URL } from 'constants/routes'
import { Filter } from 'constants/tracking/filters'
import { SortByOption } from 'constants/filter'
import { CatalogAttribute } from 'constants/catalog'
import { CatalogModel, ProductItemModel } from 'types/models'

import useAbTest from 'hooks/useAbTest'

import ItemsContainer from './ItemsContainer'
import { SellerFiltersType } from './SellerFilters/utils'
import SellerFilters from './SellerFilters'
import ProfileEmptyState from '../ProfileEmptyState'

type Props = {
  userId: number
  currentUserId: number | null | undefined
  allowBump: boolean
  userOnHoliday: boolean
  items: Array<ProductItemModel>
  itemsCount: number
  isLoading: boolean
  selectedSellerFilter: SellerFiltersType | undefined
  sortBy: SortByOption
  catalogId: number | null
  catalogs: Array<CatalogModel>
  fetchMoreItems: (selectedFilter?: SellerFiltersType) => void
  updateFilters: (newSortBy: SortByOption, newCatalogId: number | null) => void
  totalItemsCount: number
}

const trackingElementByFilterType: Record<SellerFiltersType, ClickableElement> = {
  [SellerFiltersType.Active]: ClickableElement.FilterActive,
  [SellerFiltersType.Hidden]: ClickableElement.FilterHidden,
  [SellerFiltersType.Sold]: ClickableElement.FilterSold,
  [SellerFiltersType.Reserved]: ClickableElement.FilterReserved,
  [SellerFiltersType.Drafts]: ClickableElement.FilterDrafts,
}

const ROOT_CATALOG_ID = -1

const UserItems = ({
  userId,
  currentUserId,
  allowBump,
  userOnHoliday,
  items,
  itemsCount,
  isLoading,
  selectedSellerFilter,
  sortBy,
  catalogId,
  catalogs,
  fetchMoreItems,
  updateFilters,
  totalItemsCount,
}: Props) => {
  const { track } = useTracking()
  const breakpoints = useBreakpoint()
  const asset = useAsset('/assets/animations')

  const [isClientSideReady, setIsClientSideReady] = useState(false)

  const isViewingSelf = currentUserId === userId

  const emptyStateAbTest = useAbTest({
    abTestName: 'empty_wardrobe_visual_update',
    shouldTrackExpose: true,
  })
  const isEmptyStateAbTestEnabled = emptyStateAbTest && emptyStateAbTest.variant !== 'off'

  const emptyWardrobeTextTest = useAbTest({
    abTestName: 'empty_wardrobe_text',
    shouldTrackExpose: true,
  })

  const getTitleKeySuffix = () => {
    switch (emptyWardrobeTextTest?.variant) {
      case 'a':
      case 'b':
        return '_secondary'
      default:
        return ''
    }
  }

  const getSubtitleKeySuffix = () => {
    switch (emptyWardrobeTextTest?.variant) {
      case 'a':
      case 'c':
        return '_secondary'
      default:
        return ''
    }
  }

  const emptyWardrobeCTATextTest = useAbTest({
    abTestName: 'empty_wardrobe_cta_text',
    shouldTrackExpose: true,
  })

  const isEmptyWardrobeCTATextTestEnabled =
    emptyWardrobeCTATextTest?.variant && emptyWardrobeCTATextTest?.variant !== 'off'

  useEffect(() => {
    setIsClientSideReady(true)
  }, [])

  const getTrackOpenDropdown = (filterType: Filter) => () => {
    track(
      clickFilterEvent({
        filter: filterType,
        screen: Screen.UserProfile,
      }),
    )
  }

  const trackCatalogSelection = (id: number | null) => {
    track(
      selectCatalogEvent({
        catalogIds: id ? [id] : [ROOT_CATALOG_ID],
        attributeId: CatalogAttribute.ProfileFilters,
        screen: Screen.UserProfile,
      }),
    )
  }

  const trackSortBySelection = (sortByOption: SortByOption) => {
    track(
      clickEvent({
        target: ClickableElement.FilterSortingSelection,
        screen: Screen.UserProfile,
        targetDetails: sortByOption,
      }),
    )
  }

  const handleCatalogIdChange = (newCatalogId: number | null) => {
    if (newCatalogId === catalogId) return

    updateFilters(sortBy, newCatalogId)
    trackCatalogSelection(newCatalogId)
  }

  const handleSortByChange = (newSortBy: SortByOption) => {
    if (newSortBy === sortBy) return

    updateFilters(newSortBy, catalogId)
    trackSortBySelection(newSortBy)
  }

  const handleSellerFilterClick = (filter: SellerFiltersType) => {
    const newFilter = selectedSellerFilter === filter ? undefined : filter

    const trackingEvent = clickEvent({
      target: trackingElementByFilterType[filter],
      screen: Screen.CurrentUserProfile,
    })

    if (selectedSellerFilter !== filter) track(trackingEvent)

    logMessage(`Closet filter clicked: ${filter}`, {
      feature: 'closet_filtering',
      extra: filter || 'all',
    })

    fetchMoreItems(newFilter)
  }

  const fetchMore = () => {
    fetchMoreItems(selectedSellerFilter)
  }

  const renderEmptyState = () => {
    const animation = userOnHoliday ? 'vacation' : 'closet'
    const buttonSuffix = isEmptyWardrobeCTATextTestEnabled ? '_secondary' : ''

    if (isViewingSelf) {
      return isEmptyStateAbTestEnabled ? (
        <ProfileEmptyState />
      ) : (
        <EmptyState
          testId="closet-empty-state"
          animation={<Animation animationUrl={asset('closet-empty-state.json')} />}
          title={
            <FormattedMessage
              id={`profile.items_empty_state.self_view.title${getTitleKeySuffix()}`}
            />
          }
          body={
            <FormattedMessage
              id={`profile.items_empty_state.self_view.subtitle${getSubtitleKeySuffix()}`}
            />
          }
          action={
            <Button
              text={<FormattedMessage id={`profile.items_empty_state.button${buttonSuffix}`} />}
              url={ITEM_UPLOAD_URL}
              styling={Button.Styling.Filled}
            />
          }
        />
      )
    }

    return (
      <EmptyState
        testId="closet-empty-state"
        title={<FormattedMessage id="user.items_empty.title" />}
        body={
          userOnHoliday ? (
            <FormattedMessage id="user.items_empty.on_holiday" />
          ) : (
            <FormattedMessage id="user.items_empty.no_items" />
          )
        }
        animation={<Animation animationUrl={asset(`${animation}-empty-state.json`)} />}
      />
    )
  }

  const renderItemsContainer = () => {
    return (
      <ItemsContainer
        items={items}
        userId={userId}
        allowBump={allowBump}
        isViewingSelf={isViewingSelf}
      />
    )
  }

  const renderLoader = () => (
    <div className="u-flexbox u-justify-content-center">
      <Loader size={Loader.Size.Large} testId="closet-loader" />
    </div>
  )

  const renderFilters = () => {
    return (
      <div className="u-text-left u-flexbox u-flex-wrap" data-testid="closet-buyer-filters">
        <CatalogFilter
          catalogs={catalogs}
          selectedId={catalogId}
          toggleCatalogFilterOption={handleCatalogIdChange}
          onDropdownOpen={getTrackOpenDropdown(Filter.Category)}
        />
        <SortFilter
          selected={sortBy}
          onItemClick={handleSortByChange}
          onDropdownOpen={getTrackOpenDropdown(Filter.Sort)}
        />
      </div>
    )
  }

  const renderBuyerFilters = () => {
    if (!isViewingSelf && catalogs.length) return renderFilters()

    return null
  }

  const renderItems = () => {
    const hasItems = totalItemsCount > 0

    const Wrapper = breakpoints.tabletsUp
      ? (props: HTMLAttributes<HTMLDivElement>) => <div {...props} />
      : Fragment

    const desktopRowClass = classNames('u-flexbox u-flex-wrap u-align-items-center', {
      'u-justify-content-between': hasItems,
      'u-justify-content-flex-end': !hasItems,
    })

    return (
      <>
        <Container>
          <div className={desktopRowClass}>
            {hasItems && (
              <>
                <Wrapper>
                  <Text
                    type={Text.Type.Title}
                    text={<FormattedMessage id="user.items.title" values={{ count: itemsCount }} />}
                  />
                  <Spacer orientation={Spacer.Orientation.Vertical} />
                </Wrapper>
                {renderBuyerFilters()}
              </>
            )}
            {isViewingSelf && (
              <div className="u-tablets-up-only">
                <SellerFilters
                  onFilterClick={handleSellerFilterClick}
                  selectedSellerFilter={selectedSellerFilter}
                />
              </div>
            )}
          </div>
          {isViewingSelf && (
            <div className="u-phones-only">
              <SellerFilters
                onFilterClick={handleSellerFilterClick}
                selectedSellerFilter={selectedSellerFilter}
              />
            </div>
          )}
        </Container>
        {items.length > 0 && (
          <div className="u-ui-margin-horizontal-regular@portables">
            <InfiniteScroll
              renderItems={renderItemsContainer}
              onPageEnd={fetchMore}
              cursor={`user-items-${itemsCount}`}
            />
          </div>
        )}
      </>
    )
  }

  const showEmptyState = isEmpty(items) && !isLoading && isClientSideReady

  return (
    <>
      {renderItems()}
      {showEmptyState && renderEmptyState()}
      {isLoading && renderLoader()}
    </>
  )
}

export default UserItems
